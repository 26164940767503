/* eslint-disable no-underscore-dangle */
import React from 'react';
import { BaseControl } from 'react-map-gl';
import './AtlasButton.css';

class AtlasButton extends BaseControl {
  _render() {
    let style = {};
    style.display = this.props.showButton ? 'block' : 'none';
    if (this.props.position) {
      style.position = 'absolute';
      style.zIndex = 99;
      style = { ...style, ...this.props.position };
    }
    return (
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group" style={style}>
        <button className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-reset" onClick={this.props.handler}>
          {this.props.icon}
        </button>
      </div>
    );
  }
}

export default AtlasButton;
