import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import './ZoomMenu.css';

class ZoomMenu extends React.Component {
  render() {
    return (
      <div className="zoomMenu">
        <DropdownButton id="dropdown-button" title="City Select">
          <Dropdown.Item onClick={this.props.handler} value="deventer">
            Deventer
          </Dropdown.Item>
          <Dropdown.Item onClick={this.props.handler} value="exeter">
            Exeter
          </Dropdown.Item>
          <Dropdown.Item onClick={this.props.handler} value="hamburg">
            Hamburg
          </Dropdown.Item>
          <Dropdown.Item onClick={this.props.handler} value="trento">
            Trento
          </Dropdown.Item>
          <Dropdown.Item onClick={this.props.handler} value="valencia">
            Valencia
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  }
}

ZoomMenu.propTypes = {
  handler: PropTypes.func,
};

export default ZoomMenu;
