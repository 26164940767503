import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';

import Atlas from './Atlas/Atlas.jsx';

function App() {
  return (
    <div className="App">
      <Container fluid>
        <Row>
          <Col>
            <Atlas></Atlas>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
