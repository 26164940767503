import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import './AtlasSlider.css';

class AtlasSlider extends React.Component {
  render() {
    return (
      <div className="rasterSlider">
        <Form.Control
          ref={this.slider}
          type="range"
          custom
          onChange={this.props.handler}
          min={0}
          max={1}
          step={0.01}
          defaultValue={0.5}
        />
      </div>
    );
  }
}

AtlasSlider.propTypes = {
  handler: PropTypes.func,
};

export default AtlasSlider;
