import React from 'react';
import ReactMapGL, { Source, Layer, NavigationControl, Popup, LinearInterpolator } from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/pro-solid-svg-icons';

import AtlasButton from './AtlasButton/AtlasButton.jsx';
import AtlasSlider from './AtlasSlider/AtlasSlider.jsx';
import ZoomMenu from './ZoomMenu/ZoomMenu.jsx';

class Atlas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRasters: true,
      opacity: 0.5,
      popup: {
        showPopup: false,
        latitude: null,
        longitude: null,
      },
      viewport: {
        latitude: 52.254340919510945,
        longitude: 6.157580210424782,
        zoom: 14,
        bearing: 0,
        pitch: 0,
      },
    };
  }

  coordinates = {
    deventer: {
      latitude: 52.254340919510945,
      longitude: 6.157580210424782,
    },
    exeter: {
      latitude: 50.72144988166452,
      longitude: -3.534361999209472,
    },
    hamburg: {
      latitude: 53.550260809988416,
      longitude: 9.989934106737007,
    },
    trento: {
      latitude: 46.06786549638651,
      longitude: 11.121860272007826,
    },
    valencia: {
      latitude: 39.47415751699625,
      longitude: -0.3775451931598839,
    },
  };

  zoomTo = e => {
    const location = e.target.getAttribute('value');
    const { latitude, longitude } = this.coordinates[location];
    this.setState({
      viewport: {
        ...this.state.viewport,
        longitude,
        latitude,
        transitionInterpolator: new LinearInterpolator(),
        transitionDuration: 500,
      },
    });
  };

  hideRasters = e => {
    e.stopPropagation();
    e.preventDefault();
    const { showRasters } = this.state;
    this.setState({ showRasters: !showRasters });
  };

  handleClick = e => {
    this.setState({
      popup: {
        showPopup: true,
        longitude: e.lngLat[0],
        latitude: e.lngLat[1],
      },
    });
  };

  handleSlide = e => {
    this.setState({
      opacity: parseFloat(e.currentTarget.value),
    });
  };

  render() {
    const { showPopup, latitude, longitude } = this.state.popup;
    return (
      <React.Fragment>
        <ReactMapGL
          {...this.state.viewport}
          width="calc(100vw - 20px)"
          height="calc(100vh - 20px)"
          mapStyle="mapbox://styles/axismaps/ck9aypnv50lop1ipgdhh8hzmg"
          onClick={this.handleClick}
          onViewportChange={viewport => this.setState({ viewport })}
          mapboxApiAccessToken="pk.eyJ1IjoiYXhpc21hcHMiLCJhIjoieUlmVFRmRSJ9.CpIxovz1TUWe_ecNLFuHNg"
        >
          <Source
            id="deventer"
            type="raster"
            tiles={['https://s3.amazonaws.com/hidden-cities.axismaps.io/tiles/deventer/{z}/{x}/{y}.png']}
            scheme="tms"
          >
            <Layer
              id="deventer"
              type="raster"
              paint={{ 'raster-opacity': this.state.opacity }}
              layout={{ visibility: this.state.showRasters ? 'visible' : 'none' }}
            />
          </Source>
          <Source
            id="exeter"
            type="raster"
            tiles={['https://s3.amazonaws.com/hidden-cities.axismaps.io/tiles/exeter/{z}/{x}/{y}.png']}
            scheme="tms"
          >
            <Layer
              id="exeter"
              type="raster"
              paint={{ 'raster-opacity': this.state.opacity }}
              layout={{ visibility: this.state.showRasters ? 'visible' : 'none' }}
            />
          </Source>
          <Source
            id="hamburg"
            type="raster"
            tiles={['https://s3.amazonaws.com/hidden-cities.axismaps.io/tiles/hamburg/{z}/{x}/{y}.png']}
            scheme="tms"
          >
            <Layer
              id="hamburg"
              type="raster"
              paint={{ 'raster-opacity': this.state.opacity }}
              layout={{ visibility: this.state.showRasters ? 'visible' : 'none' }}
            />
          </Source>
          <Source
            id="trento"
            type="raster"
            tiles={['https://s3.amazonaws.com/hidden-cities.axismaps.io/tiles/trento/{z}/{x}/{y}.png']}
            scheme="tms"
          >
            <Layer
              id="trento"
              type="raster"
              paint={{ 'raster-opacity': this.state.opacity }}
              layout={{ visibility: this.state.showRasters ? 'visible' : 'none' }}
            />
          </Source>
          <Source
            id="valencia"
            type="raster"
            tiles={['https://s3.amazonaws.com/hidden-cities.axismaps.io/tiles/valencia/{z}/{x}/{y}.png']}
            scheme="tms"
          >
            <Layer
              id="valencia"
              type="raster"
              paint={{ 'raster-opacity': this.state.opacity }}
              layout={{ visibility: this.state.showRasters ? 'visible' : 'none' }}
            />
          </Source>
          <div style={{ position: 'absolute', left: 10, top: 10 }}>
            <NavigationControl />
            <AtlasButton
              handler={this.hideRasters}
              icon={<FontAwesomeIcon icon={faPalette} />}
              showButton={true}
              captureClick={true}
            />
          </div>
          {showPopup && (
            <Popup
              latitude={latitude}
              longitude={longitude}
              closeButton={true}
              closeOnClick={false}
              onClose={() => this.setState({ popup: { showPopup: false } })}
              anchor="top"
            >
              <div style={{ padding: '10px 10px 0 10px' }}>
                <div>
                  <label>Longitude: </label>
                  <input readOnly={true} value={longitude} />
                </div>
                <div>
                  <label>Latitude: </label>
                  <input readOnly={true} value={latitude} />
                </div>
              </div>
            </Popup>
          )}
        </ReactMapGL>
        <AtlasSlider handler={this.handleSlide} />
        <ZoomMenu handler={this.zoomTo} />
      </React.Fragment>
    );
  }
}

export default Atlas;
